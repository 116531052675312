'use client'

import { connectToChild } from 'penpal'
import React, { ReactNode, useEffect, useMemo } from 'react'

import { InvalidTokenError } from './error'
import { IframeResizer } from './iframe-resizer'

const IFRAME_CONNECTION_TIMEOUT_MILLISECONDS = 10_000

/**
 * Renders the CapitalOS App.
 */
export function App(props: {
  /**
   * The token provided by initiate-login.
   */
  token: string
  /**
   * Optional CSS class name for the component.
   */
  className?: string
  /**
   * Optional flag indicating whether to log events to the console.
   */
  enableLogging?: boolean

  /**
   * Optional callback for when the app encounters an error.
   */
  onError?: (error: Error) => void

  /**
   * Optional component to render while the app is loading.
   */
  loadingComponent?: ReactNode
}) {
  const { token, className, enableLogging, onError, loadingComponent: LoadingComponent } = props

  const iframeRef = React.useRef<HTMLIFrameElement>(null)

  // represents the state of whether all required react queries returned and the page should be visible
  const [isLoaded, setIsLoaded] = React.useState(false)

  // memoize the url based on the token so we don't redo the computation on each render.
  const url = useMemo(() => {
    try {
      const urlDecodedToken = decodeURIComponent(token)
      const base64DecodedToken = atob(urlDecodedToken)
      const jsonToken = JSON.parse(base64DecodedToken)
      const { path } = jsonToken
      if (!path) {
        throw new InvalidTokenError()
      }
      return `${path}?token=${token}`
    } catch (error) {
      onError?.(new InvalidTokenError())
      return undefined
    }
  }, [token, onError])

  // connect to child iframe
  useEffect(() => {
    const connection = connectToChild({
      iframe: iframeRef.current!,
      childOrigin: '*',
      debug: true,
      timeout: IFRAME_CONNECTION_TIMEOUT_MILLISECONDS,
      methods: {
        onLoad: () => {
          setIsLoaded(true)
        },
      },
    })

    connection.promise.catch((error) => {
      onError?.(error)

      // when connection fails it's probably a handshake timeout with the iframe. we will stop showing the loader
      // since the fact we failed communication doesn't necessarily mean the iframe didn't load
      setIsLoaded(true)
    })

    return () => {
      connection.destroy()
    }
  }, [token])

  return (
    <>
      {/* show loader as long as we're not loaded */}
      {!isLoaded && LoadingComponent}
      {/* hide the iframe as long as we're not loaded */}
      <IframeResizer
        src={url}
        allow="clipboard-write"
        checkOrigin={false}
        style={{ width: '1px', height: '0px', minWidth: '100%' }}
        className={className}
        log={!!enableLogging}
        ref={iframeRef}
        hidden={!isLoaded}
      />
    </>
  )
}
