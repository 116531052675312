import {
  IFrameOptions as BrokenIframeOptions,
  IFrameComponent,
  IFrameMessageData,
  IFrameResizedData,
  IFrameScrollData,
  iframeResizer,
} from 'iframe-resizer'
import React, { IframeHTMLAttributes, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

// the events were renamed but package maintainers didn't update the types.
type IFrameOptions = Omit<
  BrokenIframeOptions,
  'closedCallback' | 'scrollCallback' | 'resizedCallback' | 'messageCallback' | 'initCallback'
> & {
  onClosed?(iframeId: string): void
  onInit?(iframe: IFrameComponent): void
  onMessage?(data: IFrameMessageData): void
  onResized?(data: IFrameResizedData): void
  onScroll?(data: IFrameScrollData): boolean
}

type IframeResizerProps = IFrameOptions & IframeHTMLAttributes<HTMLIFrameElement>
type EnrichedHtmlIframeElement = HTMLIFrameElement & { iFrameResizer: { removeListeners: () => void } }

export const IframeResizer = forwardRef((props: IframeResizerProps, ref: React.Ref<HTMLIFrameElement>) => {
  const title = props.title || 'iframe'
  const { iframeHTMLAttributes, resizerOptions } = splitProps(props)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    // effects run after render, so the ref is guaranteed to be set (unless the component conditionally renders the iframe, which is not the case)
    const iframe = iframeRef.current as EnrichedHtmlIframeElement

    iframeResizer({ ...resizerOptions }, iframe)

    return () => iframe.iFrameResizer && iframe.iFrameResizer.removeListeners()
  })

  // make the ref provided as a prop point to the same place as the internal iframe ref.
  useImperativeHandle(ref, () => iframeRef.current as HTMLIFrameElement)

  return <iframe {...iframeHTMLAttributes} title={title} ref={iframeRef} />
})

IframeResizer.displayName = 'IframeResizer'

const resizerOptions = [
  'autoResize',
  'bodyBackground',
  'bodyMargin',
  'bodyPadding',
  'checkOrigin',
  'inPageLinks',
  'heightCalculationMethod',
  'interval',
  'log',
  'maxHeight',
  'maxWidth',
  'minHeight',
  'minWidth',
  'resizeFrom',
  'scrolling',
  'sizeHeight',
  'sizeWidth',
  'warningTimeout',
  'tolerance',
  'widthCalculationMethod',
  'onClosed',
  'onInit',
  'onMessage',
  'onResized',
  'onScroll',
]

const resizerOptionsSet = new Set(resizerOptions)

/**
 * split props into the resizer library options and the native iframe attributes.
 * the code is contains many type assertions because typescript doesn't handle reduce well.
 */
function splitProps(props: IframeResizerProps) {
  const split = Object.keys(props).reduce<{
    resizerOptions: IFrameOptions
    iframeHTMLAttributes: IframeHTMLAttributes<HTMLIFrameElement>
  }>(
    (acc, key) => {
      if (resizerOptionsSet.has(key)) {
        acc.resizerOptions[key as keyof IFrameOptions] = props[key as keyof typeof props]
      } else {
        acc.iframeHTMLAttributes[key as keyof IframeHTMLAttributes<HTMLIFrameElement>] =
          props[key as keyof typeof props]
      }
      return acc
    },
    { resizerOptions: {}, iframeHTMLAttributes: {} },
  )

  return split
}
