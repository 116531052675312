/**
 * Base class for all SDK errors
 */
export class CapitalOSError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'CapitalOSError'
  }
}

/**
 * Represents an error that occurs when an invalid token is encountered.
 */
export class InvalidTokenError extends CapitalOSError {
  constructor() {
    super('Invalid token')
    this.name = 'CapitalOSInvalidTokenError'
  }
}
